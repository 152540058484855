import Vue from 'vue'
import Router from 'vue-router'
import Home from 'views/Home'
import Login from 'views/Login'
import CreateNewGame from 'views/CreateNewGame'
import Register from 'views/Register'
import JoinMatch from 'views/JoinMatch'
import JoinTeam from 'views/JoinTeam'
import MyPage from 'views/MyPage'
import GamePlayMatch from 'views/GamePlayMatch'
import GamePlayTeam from 'views/GamePlayTeam'
import FindPw from 'views/FindPw'

Vue.use(Router)

const routes = [
  {
    path: '/',
    component: Home
  },
  {
    path: '/login',
    name:'login',
    component: Login
  },
  {
    path: '/createNewGame',
    name:'createNewGame',
    component: CreateNewGame
  },
  {
    path: '/joinMatch',
    name:'joinMatch',
    component: JoinMatch
  },
  {
    path: '/joinTeam',
    name:'joinTeam',
    component: JoinTeam
  },
  {
    path: '/myPage',
    name:'myPage',
    component: MyPage
  },
  {
    path: '/gamePlayMatch',
    name:'gamePlayMatch',
    component: GamePlayMatch
  },
  {
    path: '/gamePlayTeam',
    name:'gamePlayTeam',
    component: GamePlayTeam
  },
  {
    path: '/register',
    component: Register
  },
  {
    path: '/findpw',
    component: FindPw
  },
  {
    path: '/404',
    component: () => import('views/exception/404')
  },
  {
    path: '*',
    redirect: '/404',
  }
]
const router = new Router({
  routes,
  mode: 'history'
})
export default router
