<template>
  <div class="bottom">
    <div class="content pc">
      <div style="display: flex;flex-direction:column;justify-content:center;align-items:center;cursor: pointer;position: fixed;bottom:20px;">
        <img src="../assets/img/join_us.png" style="width:60px;"/>
        <div style="color:#ffffff;margin-top:10px;">Join us</div>
      </div>
    </div>
  </div>
</template>

<script>
  import { getAction } from '@/network/manage'
  export default {
    name: 'Bottom',
    data() {
      return {
      }
    },
    created(){
    },
    methods:{

    }
  }
</script>

<style scoped>
  .bottom{background-color:#000000;color:#ffffff;}
  .content{margin:auto;display: flex;justify-content: space-between;align-items: center;align-items: center;}
  .content .right{font-family: 'ABeeZee';font-size: 13px;color: #4A4A4A;}
  @media (max-width: 800px) {
  	.pc{display: none !important;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;display: flex;justify-content: flex-end;}
  }
  @media(min-width: 1200px){
    .content{width:1200px;display: flex;justify-content: flex-end;}

  	.mobile{display: none !important;}
  }
</style>
