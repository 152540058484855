<template>
  <div class="body" v-loading="formLoading">
    <div class="bg"></div>
    <div class="content">
      <div class="title">New Register</div>
      <div class="info">
        <div class="item">
          <span>E-mail (ID)</span>
          <input v-model="param.email" maxlength="50"/>
          <div class="box-item">
            <input v-model="param.email_verification" placeholder="Enter the 6-digit code sent to ******"/>
            <a v-if="emailTime == 120" @click="sendEmailCode" v-loading="emailLoading">Get code</a>
            <a v-if="emailTime != 120">{{emailTime}}s resend</a>
          </div>
        </div>

        <div class="item">
          <span>Password</span>
          <input v-model="param.password" maxlength="50" type="password" class="password"/>
        </div>
        <div class="item">
          <span>Confirm Password</span>
          <input v-model="param.password2" maxlength="50" type="password" class="password"/>
        </div>

        <div class="item">
          <span>Battle TAG (GAME NAME)</span>
          <input v-model="param.nick_name" maxlength="50"/>
        </div>

        <div class="btn ok" @click="reg">Register</div>
      </div>
    </div>
    <hash-bottom></hash-bottom>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { postActionApi,getActionApi } from '@/network/manageApi'
  import { ACCESS_TOKEN,HEAD_IMG_URL,USER_UUID } from '@/store/mutation-types'
  import { mapGetters } from 'vuex'
  import HashBottom from "components/HashBottom"
  export default {
    name: 'Register',
    components: {
      HashBottom
    },
    data() {
      return {
        emailTime:120,
        formLoading:false,
        emailLoading:false,
        emailTimer:null,
        emailCodeId:'',
        emailValidate:'^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$',
        param:{password:'',password2:'',email:'',email_verification:'',nick_name:''},
        url: {
          emailCode:'verification/request-email-verification-code',
          emailValidate:'verification/email-verification',
          reg:'user-management/signup'
        }
      }
    },
    computed: {
      ...mapGetters(['token']),
    },
    created(){
      if(this.token !== null && this.token !== '') {
        window.location.href='/'
      }
    },
    methods:{
      goPage(url){
        this.$router.push({path:url});
      },
      sendEmailCode(){
        const that = this;
        this.param.email = this.param.email.trim();
        var re = new RegExp(this.emailValidate);
        if(this.param.email == '') {
          this.$message.error('email is required!');
        } else if(!re.test(this.param.email)) {
          this.$message.error('Incorrect email format!');
        } else {
          this.emailLoading = true;
          postActionApi(this.url.emailCode, {email:this.param.email}).then((res) => {
            if (res.code == 200) {
              that.emailCodeId = res.data.codeId
              //daojishi
              that.emailTimer = setInterval(() =>{
                  that.emailTime = that.emailTime - 1;
                  this.emailLoading = false;
                  if(that.emailTime < 0){
                    clearInterval(that.emailTimer);
                    that.emailTime = 120;
                  }
              },1000)
            } else {
              that.$message.error(res.message);
              this.emailLoading = false;
            }
          }).catch(res => {
            that.$message.warning(res.message);
            this.emailLoading = false;
          })
        }
      },
      reg(){
        const that = this;
        var re = new RegExp(this.emailValidate);
        that.param.password = that.param.password.trim();
        that.param.password2 = that.param.password2.trim();
        that.param.email = that.param.email.trim();
        that.param.email_verification = that.param.email_verification.trim();
        that.param.nick_name = that.param.nick_name.trim();
        if(that.param.password == ''){
          this.$message.error('password is required!');
        }else if(that.param.password.length < 6){
          this.$message.error('The minimum password length is 6 digits');
        }else if(that.param.password2 == ''){
          this.$message.error('Confirm Password is required!');
        }else if(that.param.password2 != that.param.password){
          this.$message.error('the two passwords are inconsistent!');
        }else if(that.param.email == ''){
          this.$message.error('email is required!');
        }else if(!re.test(this.param.email)){
          this.$message.error('Incorrect email format!');
        }else if(that.param.email_verification == ''){
          this.$message.error('email code is required!');
        }else if(that.param.email_verification.length != 6){
          this.$message.error('enter the 6-digit email code!');
        }else if(that.param.nick_name == ''){
          this.$message.error('Battle TAG is required!');
        }else{
          //验证邮箱
          this.formLoading = true;
          let obj = {email:that.param.email,codeId:that.emailCodeId,verification_code:parseInt(that.param.email_verification)}
          postActionApi(that.url.emailValidate,obj).then(resList => {
            if(resList.code != 200){
              that.$message.error(resList.message);
              that.formLoading = false;
            } else {
              //注册
              let params = {
                password:that.param.password,
                email:that.param.email,
                nick_name:that.param.nick_name,
                email_verification:that.emailCodeId
              }
              postActionApi(this.url.reg, params).then(res => {
                if (res.code == 200) {
                  postActionApi('/user-management/login',{email:that.param.email,password:that.param.password}).then((resLogin) => {
                    if (resLogin.code == 200) {
                      //存储token
                      Vue.ls.set(ACCESS_TOKEN, resLogin.data.token, 10 * 60 * 60 * 1000)
                      getActionApi('/user-management/my-information').then((resInfo) => {
                        if(resInfo.code == 200){
                          if(resInfo.data.contents.length > 0 && resInfo.data.contents[0].user_profile_photo) {
                            Vue.ls.set(HEAD_IMG_URL, resInfo.data.contents[0].user_profile_photo, 10 * 60 * 60 * 1000)
                          }
                          Vue.ls.set(USER_UUID, resInfo.data.contents[0].user_uuid, 10 * 60 * 60 * 1000)
                          window.location.href='/'
                        } else {
                          that.msg = resInfo.message;
                          that.formLoading = false;
                        }
                      }).catch(res => {
                        that.msg = res.message;
                      }).finally(() => {
                        that.formLoading = false;
                      })
                    } else {
                      that.$message.error(resLogin.message);
                    }
                  }).catch(res => {
                    that.$message.warning(res.message);
                  }).finally(() => {
                    that.formLoading = false;
                  })
                } else {
                  that.$message.error(res.message);
                }
              }).catch(err => {
                that.$message.warning(err.message);
              }).finally(() => {
                that.formLoading = false;
              })
            }
          }).catch(err => {
            that.$message.warning(err.message);
            that.formLoading = false;
          })
        }
      }
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'ABeeZee';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .body{text-align: center;}
  .content{display: flex;flex-direction: column;padding:30px 0 60px 0;margin:auto;align-items: center;}
  .title{font-size: 22px;letter-spacing: -1px;margin:30px 0;font-family: 'ABeeZee';font-weight: bold;color: #00FF00;}
  .password{background-image: url(../assets/img/password.png);background-repeat: no-repeat;background-position: right 10px center;background-size: 20px;}
  .msg{font-family: 'ABeeZee';color: #C90000;font-weight: bold;text-align: left;}
  .msg-item{margin-bottom:0 !important;}
  .login-type{display: flex;margin-bottom: 20px;}
  .login-type span{background: #D9D9D9;font-family: 'ABeeZee';font-weight: bold;border-radius: 18px;width:110px;height:40px;display: flex;align-items: center;justify-content: center;margin-right:20px;}
  .login-type .act{background: #01388B;color:#ffffff;}
  .check-div{display: flex;margin-top:10px;align-items: center;font-family: 'ABeeZee';letter-spacing: -0.6px;}
  .check-div input{width:20px;height:20px;margin-right:10px;}
  .check-div a{color:#007AFF;cursor: pointer;}
  .remark{color:#888888;letter-spacing: -0.45px;font-family: 'ABeeZee';}
  .remark a{color:#0020C9;font-weight: bold;cursor: pointer;}
  .link{font-family: 'ABeeZee';color: #0020C9;text-align: left;letter-spacing: -0.6px;cursor: pointer;font-weight: bold;font-size:15px;margin-bottom:5px;}
  .box-item{border: 1px solid #C4C4C4;border-radius: 8px;width:100%;height:38px;display: flex;margin-top:10px;align-items: center;}
  .box-item input{width:100%;border-width: 0 !important;height:36px !important;}
  .box-item a{flex-shrink: 0;color:#00FF00;font-family: 'ABeeZee';font-size:15px;cursor: pointer;margin-right:10px;}
  .content .info{width:600px;padding:20px 80px;border-radius: 10px;}
  .content .info .item{display: flex;flex-direction: column;margin-bottom:20px;align-items: flex-start;}
  .content .info .item span{font-size:14px;margin-bottom:6px;font-family: 'ABeeZee';color: #ffffff;}
  .content .info .item input{border: 1px solid #ffffff;border-radius: 8px;width: 100%;color: #ffffff;height: 38px;padding: 0 10px;background-color: transparent;}
  .content .info .item textarea{border: 1px solid #C4C4C4;border-radius: 8px;width:100%;height:160px;padding:10px;}
  .content .info .item .bord{border-radius: 8px;border: 1px solid #5F5F5F;}
  .content .info .item .bord input{border-width: 0px;}
  .content .info .item .bord textarea{border-width: 0px;border-top: 1px solid #5F5F5F;border-radius: 0px;}
  .content .info .btn{width: 100%;height: 40px;line-height:1;margin:40px 0 10px 0;color:#0a4a0a;background: #AFAFAF;border-radius: 6px;display: flex;align-items: center;justify-content: center;}
  .content .info .ok{background: #00FF00;cursor: pointer;}
  a{text-decoration: none;color:inherit;}
  @media (max-width: 800px) {
    .pc{display: none !important;}
    .title{margin:100px 0 40px 0 !important;font-size:24px !important;}
    .content{padding:0 0 10px 0 !important;}
    .content .info{width:100%;padding:20px;margin-top:0;}
    .title{font-size: 20px;margin:16px 0;}
    .address{display: flex;flex-direction: column;align-items: flex-start;text-align: left;padding:0 20px;}
    .content .info .item span{margin-bottom:10px;}
    .msg{font-weight: bold;text-align: center;margin-top:20px;}
    .link{text-align: center;margin:10px 0;}
    .check-div{margin:0 10vw 10px 10vw;}
    .title-remark{font-size: 15px;letter-spacing: -0.24px;margin-bottom:10px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;}
  }
</style>
