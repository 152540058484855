<template>
  <div class="body" v-loading="formLoading">
    <div class="bg"></div>
    <div class="content">
      <div class="title">CREATE NEW GAME</div>
      <div class="info">
        <div class="item">
          <p>Title</p>
        </div>
        <input class="input" v-model="param.title"/>
        <div class="item">
          <p>Rules</p>
        </div>
        <textarea style="height:80px;resize:none;" class="input" v-model="param.rules"></textarea>
        <div class="item">
          <p>PVP type (Solo or Team Match)</p>
          <div>
            <select v-model="param.pvp_type">
              <option value="">Select</option>
              <option :value="0">Solo</option>
              <option :value="1">Team</option>
            </select>
          </div>
        </div>
        <div class="item">
          <p>Total Number of participants</p>
          <div>
            <input v-model="param.total_number_of_participants"/>
            <span>{{param.pvp_type == 1?'Teams':'Users'}}</span>
          </div>
        </div>
        <div class="item" v-if="param.pvp_type == 1">
          <p>Users in a team</p>
          <div>
            <input v-model="param.team_participants"/>
            <span>Users</span>
          </div>
        </div>
        <div class="item">
          <p>Application period</p>
          <div>
            <input v-model="param.apply_period"/>
            <span>Days</span>
          </div>
        </div>
        <hr/>
        <div class="item">
          <p>Capaign period</p>
          <div>
            <input v-model="param.campaign_period"/>
            <span>Days</span>
          </div>
        </div>
        <div class="item">
          <p>Battle type (Melee or Magic)</p>
          <div>
            <select v-model="param.battle_type">
              <option value="">Select</option>
              <option :value="0">Melee</option>
              <option :value="1">Magic</option>
            </select>
          </div>
        </div>
        <div class="item">
          <p>Number of battle round</p>
          <div>
            <select v-model="param.number_of_battle_round">
              <option value="">Select</option>
              <option :value="1">1 Rounds</option>
              <option :value="2">2 Rounds</option>
              <option :value="3">3 Rounds</option>
            </select>
          </div>
        </div>
        <div class="item">
          <p>First user to win x games / round</p>
          <div>
            <select v-model="param.round_win_first">
              <option value="">Select</option>
              <option :value="1">1 wins</option>
              <option :value="3">3 wins</option>
              <option :value="5">5 wins</option>
              <option :value="10">10 wins</option>
              <option :value="15">15 wins</option>
              <option :value="20">20 wins</option>
            </select>
          </div>
        </div>
        <div class="btn" @click="submit_game">CREATE</div>
      </div>
    </div>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { postActionApi } from '@/network/manageApi'
  import { getAction,postAction } from '@/network/manage'
  import { ACCESS_TOKEN } from '@/store/mutation-types'
  import { mapGetters } from 'vuex'
  export default {
    name: 'CreateNewGame',
    components: {
    },
    data() {
      return {
        msg:'',
        loginType:0,
        formLoading:false,
        certName:'',
        certRemark:'',
        param:{
          "title": "",
          "rules": "",
          "apply_period": "",
          "total_number_of_participants": "",
          "team_participants": "",
          "campaign_period": "",
          "number_of_battle_round": "",
          "round_win_first": "",
          "pvp_type": "",
          "battle_type": ""
        },
        url: {

        }
      }
    },
    computed: {

    },
    created(){

    },
    methods:{
      submit_game() {
        if(this.param.title === "") {
          this.$message.error('Title is required!');
          return;
        }
        if(this.param.rules === "") {
          this.$message.error('Rules is required!');
          return;
        }
        if(this.param.pvp_type === "") {
          this.$message.error('PVP type is required!');
          return;
        }

        if(this.param.total_number_of_participants === "" || isNaN(parseInt(this.param.total_number_of_participants))) {
          this.param.total_number_of_participants = ''
          this.$message.error('Total Number of participants is required!');
          return;
        }
        this.param.total_number_of_participants = parseInt(this.param.total_number_of_participants)
        if(!this.check_integer(this.param.total_number_of_participants) || this.param.total_number_of_participants <= 0) {
          this.$message.error('Total Number of participants Must fill in a positive integer!');
          return;
        }

        if(this.param.pvp_type === 1) {
          if((this.param.team_participants === "" || isNaN(parseInt(this.param.team_participants)))) {
            this.param.team_participants = ''
            this.$message.error('Users in a team is required!');
            return;
          }
          this.param.team_participants = parseInt(this.param.team_participants)
          if(!this.check_integer(this.param.team_participants) || this.param.team_participants <= 0) {
            this.$message.error('Users in a team Must fill in a positive integer!');
            return;
          }
          if(this.param.team_participants > 4 || this.param.team_participants <= 1) {
            this.$message.error('Users in a team Must be greater than 1 and not greater than 4!');
            return;
          }
        } else {
          this.param.team_participants = 1
        }

        if(this.param.apply_period === "" || isNaN(parseInt(this.param.apply_period))) {
          this.param.apply_period = ''
          this.$message.error('Application period is required!');
          return;
        }
        this.param.apply_period = parseInt(this.param.apply_period)
        if(!this.check_integer(this.param.apply_period) || this.param.apply_period <= 0) {
          this.$message.error('Application period Must fill in a positive integer!');
          return;
        }
        if(this.param.campaign_period === "" || isNaN(parseInt(this.param.campaign_period))) {
          this.param.campaign_period = ''
          this.$message.error('Capaign period is required!');
          return;
        }
        this.param.campaign_period = parseInt(this.param.campaign_period)
        if(!this.check_integer(this.param.campaign_period) || this.param.campaign_period <= 0) {
          this.$message.error('Capaign period Must fill in a positive integer!');
          return;
        }
        if(this.param.battle_type === "") {
          this.$message.error('Battle type is required!');
          return;
        }
        if(this.param.number_of_battle_round === "") {
          this.$message.error('Number of battle round is required!');
          return;
        }
        if(this.param.round_win_first === "") {
          this.$message.error('First user to win x games / round is required!');
          return;
        }
        this.$confirm('Are you sure to create this game?', 'Tip', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          this.formLoading = true;
          postActionApi("/game-management/game",this.param).then(res => {
            if(res.code == 200){
              this.$message.success("new game created successfully~");
              this.goPage("/")
            } else {
              this.$message.warning(res.message);
            }
          }).catch(err => {
            this.$message.warning(err.message);
          }).finally(() => {
            this.formLoading = false;
          })
        }).catch(() => {
          console.log(this.param);
        });
      },
      check_integer(num) {
        return /^\d+$/.test(num);
      },
      goPage(url){
        this.$router.push({path:url});
      },
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'ABeeZee';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .body{text-align: center;}
  .title{font-size: 30px;color:#00FF00;margin-bottom:30px;}
  .content{display: flex;flex-direction: column;margin:auto;align-items: center;padding:80px 0;}
  .content .info{width:600px;display: flex;flex-direction: column;align-items: center;}
  .content .info hr{width:100%;height:0px;border:none;border-bottom:1px dashed #00FF00;margin:20px 0 6px 0;}
  .content .item{width:100%;display: flex;justify-content: flex-start;margin-top:10px;}
  .content .item p{width:100%;text-align: left;color:#ffffff;}
  .content .item div{width:130px;flex-shrink: 0;display: flex;color:#ffffff;}
  .content .item div input{width:80px;margin-right:10px;text-align: center;font-size:14px;}
  .content .item div select{width:80px;margin-right:10px;text-align: center;font-size:14px;}
  .content .input{width:100%;min-height:30px;}
  .content .btn{width:90px;cursor: pointer;height: 40px;margin-top:30px;line-height:1;margin:30px 0 30px 0;background:#0500FF;color:#ffffff;border-radius: 6px;display: flex;align-items: center;justify-content: center;}
  a{text-decoration: none;color:#5C6BC0;}
  @media (max-width: 800px) {
    .pc{display: none !important;}
    .body{min-height:100vh;padding-top:60px;}
    .body *{font-size:14px;}
    .content{padding:0 0 30px 0 !important;}
    .content .info{width:100%;padding:10px;margin-top:0;}
    .title{font-size: 20px;margin:16px 0;}
    .content .btn{width:80px;height: 36px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;min-height:calc(100vh - 206px);}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;min-height:calc(100vh - 206px);}
  }
</style>
