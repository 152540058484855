<template>
  <div class="header">
    <!--头部-->
    <div class="pc content">
      <div class="header_left">
        <img src="../assets/img/logo.png" @click="goPage('/')"/>
      </div>
      <div class="header_center">
        <img src="../assets/img/d2pvp.png" @click="goPage('/')"/>
      </div>
      <div class="header_right">
        <div class="before-login" v-if="!isLogin">
          <a @click="goPage('login')">Log-in</a>
          |
          <a @click="goPage('register')">Register</a>
        </div>
        <div class="after-login" v-if="isLogin">
          <img :src="headimgurl" v-if="headimgurl" style="width:28px;height: 28px;cursor:default;border-radius: 50%;border:1px solid #ffffff;"/>
          <img v-else src="../assets/img/default_logo.jpg" style="width:28px;height: 28px;cursor:default;border-radius: 50%;border:1px solid #ffffff;"/>
          <div class="my-page" @click="goPage('myPage')">My page</div>
          <img @click="exitLogin" src="../assets/img/exit.png"/>
        </div>
      </div>
    </div>
    <div class="headerMobile mobile">
      <div class="fixed">
        <div class="left">
          <img src="../assets/img/d2pvp.png" @click="goPage('/')"/>
        </div>
        <div class="right">
          <div class="my-page" v-if="isLogin" @click="goPage('myPage')">My page</div>
          <div class="log-in" v-if="!isLogin" @click="goPage('login')">Log-in</div>
          <div class="log-in" v-if="!isLogin">|</div>
          <div class="log-in" v-if="!isLogin" @click="goPage('register')">Register </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex'
  import { getAction } from '@/network/manage'
  import { ACCESS_TOKEN,HEAD_IMG_URL,USER_UUID } from '@/store/mutation-types'
  import Vue from 'vue'

  export default {
    name: 'Header',
    data() {
      return {
      }
    },
    computed: {
      ...mapGetters([
        'token','headimgurl'
      ]),
      isLogin() {
        return this.token !== null && this.token !== ''
      },
    },
    created(){
    },
    mounted() {
    },
    methods: {
      goPage(url){
        this.$router.push({path:url});
      },
      exitLogin(){
        this.$confirm('Are you sure to exit the system?', 'Tip', {
          confirmButtonText: 'Yes',
          cancelButtonText: 'Cancel',
          type: 'warning'
        }).then(() => {
          Vue.ls.remove(ACCESS_TOKEN);
          Vue.ls.remove(HEAD_IMG_URL);
          Vue.ls.remove(USER_UUID);
          window.location.href='/'
        }).catch(() => {

        });
      }
    }
  }
</script>

<style scoped>
  .header{position: fixed;z-index:1000;top:0;left:0;width:100%;display: flex;justify-content: space-between;align-items: center;background:#000000;}
  .content{margin:auto;display: flex;justify-content: space-between;align-items: center;color:#ffffff;}
  .header_left{display: flex;align-items: center;width:200px;}
  .header_left img{height:24px;cursor: pointer;color:#ffffff;}
  .header_center img{height:24px;cursor: pointer;color:#ffffff;}
  .header_right {width:200px;display: flex;justify-content: flex-end;}
  .header_right .before-login{padding-right:10px;display:flex;font-family: 'ABeeZee';font-size:18px;color:#ffffff;}
  .header_right .before-login a{margin:0 10px;font-family: 'ABeeZee';font-size:18px;cursor: pointer;color:#ffffff;}
  .header_right .before-login a:hover{color:#1DF200;}
  .header_right .after-login{display: flex;align-items: center;}
  .header_right .after-login .my-page{cursor: pointer;color:#ffffff;font-size: 16px;height:24px;width:95px;background-color: #0500FF;border-radius: 12px;margin:0 10px;display: flex;align-items: center;justify-content: center;}
  .header_right .after-login img{width:20px;cursor: pointer;}
  @media (max-width: 800px) {
  	.content{width:100%;}
    .pc{display:none;}
    .headerMobile .fixed{z-index:1000;position: fixed;top:0;left:0;width:100%;height:60px;display:flex;justify-content: space-between;align-items: center;}
    .headerMobile .fixed .left img{height:16px;margin-left:20px;}
    .headerMobile .fixed .right{display: flex;margin-right:20px;}
    .headerMobile .fixed .my-page{color:#ffffff;font-size: 16px;height:24px;width:90px;background-color: #0500FF;border-radius: 12px;display: flex;align-items: center;justify-content: center;margin:16px;}
    .headerMobile .fixed .log-in{font-size: 16px;color: #ffffff;margin-left:5px;}

  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.content{width:800px;}
    .mobile{display:none;}
    .header{height:80px;}
    .header_left .menu a{margin:0 6px;font-size:14px;}
    .header_left img{height:36px;margin-right:6px;}
    .header_right .before-login{font-size:14px;}
    .header_right .before-login a{font-size:14px;}
    .header_right .after-login .menu span{padding-left:28px;background-position: center left 8px;background-size: auto 14px;width:120px;height:30px;margin:0 5px;font-size:13px;}
    .header_right .after-login .my-page{font-size: 12px;height:20px;width:70px;border-radius: 10px;margin:0 5px;display: flex;align-items: center;justify-content: center;}
    .header_right .after-login img{width:16px;}
  }
  @media(min-width: 1200px){
  	.content{width:1200px;}
    .mobile{display:none;}
    .header{height:80px;}
  }
</style>
