<template>
  <div class="body" v-loading="formLoading">
    <div class="content">
      <div class="title"><a>({{game_data.battle_type}})</a> {{game_data.title}}</div>
      <div class="btn" style="background-color: #FF0000;" v-if="isMaster" @click="isConfirm_delete = true">DELETE</div>
      <div class="btn" v-if="!isMaster && !isJoin" @click="isConfirm_join = true">Join the game</div>
      <div class="btn act" v-if="!isMaster && isJoin" @click="isConfirm_leave = true">Leave</div>
      <div class="info">
        <div class="itemp">
          <p>Rules</p>
        </div>
        <textarea style="height:80px;resize:none;" class="input pc" readonly v-model="game_data.rules"></textarea>
        <div class="itemp">
          <p>PVP type : {{game_data.pvp_type}} Match</p>
        </div>
        <div class="itemp">
          <p>Application period :  {{game_data.apply_period_start_date}} ~ {{game_data.apply_period_end_date}}</p>
        </div>
        <div class="itemp">
          <p>Campaign period :  {{game_data.campaign_period_date_start}} ~ {{game_data.campaign_period_date_end}}</p>
        </div>
        <div class="itemp">
          <p>Paticipants : {{auto_comple_zero(game_data.applied_team_count)}}  / {{auto_comple_zero(game_data.max_number_of_team)}}</p>
        </div>
        <div class="listDiv">
          <div class="header">
            <span>User</span>
            <span>Ranking</span>
            <span>Ladder points (wins)</span>
            <span>norms</span>
          </div>
          <div class="list">
            <div class="item" v-for="item,index in this.game_data.team_detail" :key="index">
              <span>
                <img class="headimg" :src="item.members[0].user_profile_photo" v-if="item.members[0].user_profile_photo != null"/>
                <img class="headimg" src="../assets/img/default_logo.jpg" v-else/>
                {{item.members[0].nick_name}}
              </span>
              <span>{{item.members[0].user_rank}}</span>
              <span>{{item.members[0].user_score}} ({{item.members[0].game_number_of_won}} / {{item.members[0].profile_total_battle}})</span>
              <span>{{item.members[0].toxic_score}}<b v-if="isMaster && useruuid != item.members[0].user_uuid" @click="cut_off(item.members[0].nick_name, item.team)">×</b></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="confirmWindow" v-if="isConfirm_join">
      <div class="con">
        <div class="close">
          <b @click="isConfirm_join = false">×</b>
        </div>
        <p>Do you want to join this campaign ?</p>
        <div class="btn" @click="join_game">Confirm</div>
      </div>
    </div>
    <div class="confirmWindow" v-if="isConfirm_leave">
      <div class="con">
        <div class="close">
          <b @click="isConfirm_leave = false">×</b>
        </div>
        <p>Do you want to leave this campaign ?</p>
        <div class="btn" @click="leave_game">Confirm</div>
      </div>
    </div>
    <div class="confirmWindow" v-if="isConfirm_delete">
      <div class="con">
        <div class="close">
          <b @click="isConfirm_delete = false">×</b>
        </div>
        <p>Do you want to delete this campaign ?</p>
        <div class="btn" @click="remove_game">Confirm</div>
      </div>
    </div>
    <div class="confirmWindow" v-if="isConfirm_cutoff">
      <div class="con">
        <div class="close">
          <b @click="isConfirm_cutoff = false">×</b>
        </div>
        <p>Do you want to cut off “{{cut_name}}” ?</p>
        <div class="btn" @click="cut_off_go">Confirm</div>
      </div>
    </div>
    <HashBottom></HashBottom>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { postActionApi,getActionApi,deleteActionApi } from '@/network/manageApi'
  import { getAction,postAction } from '@/network/manage'
  import { mapGetters } from 'vuex'
  import HashBottom from "components/HashBottom"
  export default {
    name: 'JoinMatch',
    components: {
      HashBottom
    },
    data() {
      return {
        isConfirm_join:false,
        isConfirm_leave:false,
        isConfirm_delete:false,
        isConfirm_cutoff:false,
        formLoading:false,
        uuid: '',
        game_data: {applied_team_count:0,max_number_of_team:0},
        isMaster:false,
        isJoin:false,
        team_uuid: '',
        cut_name: '',
        cut_team: ''
      }
    },
    computed: {
      ...mapGetters(['useruuid'])
    },
    created(){
      if(!this.$route.query.uuid) {
        this.$router.push({path:'/'});
      } else {
        this.uuid = this.$route.query.uuid
      }
      this.init()
    },
    methods:{
      init() {
        this.isConfirm_join = false
        this.isConfirm_leave = false
        this.isConfirm_delete = false
        this.isConfirm_cutoff = false
        this.formLoading = true
        getActionApi('/game-management/game-detail?game_uuid=' + this.uuid).then(res => {
          if (res.code == 200) {
            this.game_data = res.data.contents
            if(this.game_data.team_detail.length == 0) {
              this.$router.push({path:'/'});
              return;
            }
            //状态判断 started 跳转到比赛  end 去首页
            if(this.game_data.pvp_type == 'Team') {
              if(this.game_data.game_status == 'started') {
                this.$router.push({path:'gamePlayTeam',query:{uuid:this.uuid}});
                return;
              } else if(this.game_data.game_status == 'end') {
                this.$router.push({path:'/'});
                return;
              } else {
                this.$router.push({path:'joinTeam',query:{uuid:this.uuid}});
                return;
              }
            } else {
              if(this.game_data.game_status == 'started') {
                this.$router.push({path:'gamePlayMatch',query:{uuid:this.uuid}});
                return;
              } else if(this.game_data.game_status == 'end') {
                this.$router.push({path:'/'});
                return;
              }
            }

            if(this.useruuid == this.game_data.user_uuid) {
              this.isMaster = true
            }
            for(let item of this.game_data.team_detail) {
              if(this.useruuid == item.members[0].user_uuid) {
                this.isJoin = true
                this.team_uuid = item.team
              }
            }
            this.formLoading = false
          }
        })
      },
      join_game() {
        this.formLoading = true;
        postActionApi("/game-management/join-game",{"game_uuid": this.uuid}).then(res => {
          this.formLoading = false;
          if(res.code == 200){
            this.$message.success("Join successfully~");
            this.init()
          } else {
            this.$message.warning(res.message);
            this.formLoading = false;
          }
        }).catch(err => {
          this.$message.warning(err.message);
          this.formLoading = false;
        })
      },
      leave_game() {
        this.formLoading = true;
        deleteActionApi("/game-management/join-game?team_id=" + this.team_uuid).then(res => {
          this.formLoading = false;
          if(res.code == 200){
            this.$message.success("Leave successfully~");
            this.$router.push({path:'/'});
          } else {
            this.$message.warning(res.message);
            this.formLoading = false;
          }
        }).catch(err => {
          this.$message.warning(err.message);
          this.formLoading = false;
        })
      },
      remove_game() {
        deleteActionApi("/game-management/my-game?game_uuid=" + this.uuid).then(res => {
          this.formLoading = false;
          if(res.code == 200){
            this.$message.success("Delete successfully~");
            this.$router.push({path:'/'});
          } else {
            this.$message.warning(res.message);
            this.formLoading = false;
          }
        }).catch(err => {
          this.$message.warning(err.message);
          this.formLoading = false;
        })
      },
      auto_comple_zero(number) {
        return number.toString().length == 1?("0" + number):number
      },
      cut_off(nickname, team) {
        this.isConfirm_cutoff = true
        this.cut_name = nickname
        this.cut_team = team
      },
      cut_off_go() {
        deleteActionApi("/game-management/join-game?team_id=" + this.cut_team).then(res => {
          this.formLoading = false;
          if(res.code == 200){
            this.$message.success("cut off successfully~");
            this.init()
          } else {
            this.$message.warning(res.message);
            this.formLoading = false;
          }
        }).catch(err => {
          this.$message.warning(err.message);
          this.formLoading = false;
        })
      }
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'ABeeZee';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .body{text-align: center;}
  .headimg {width:30px;height:30px;border-radius: 50%;border:2px #ffffff solid;flex-shrink: 0;}
  .title{font-size: 24px;color:#00FF00;margin-bottom:0px;}
  .title a{color:#657EFF;font-size: 24px;}
  .content{display: flex;flex-direction: column;margin:auto;align-items: center;padding:30px 0 0 0;}
  .content .info{width:600px;display: flex;flex-direction: column;align-items: center;}
  .content .info hr{width:100%;height:0px;border:none;border-bottom:1px dashed #00FF00;margin:20px 0 6px 0;}
  .content .itemp{width:100%;display: flex;justify-content: flex-start;margin-top:4px;}
  .content .itemp p{width:100%;text-align: left;color:#ffffff;}
  .content .input{width:100%;min-height:30px;}
  .content .btn{width:160px;cursor: pointer;height: 40px;line-height:1;margin:10px 0 30px 0;background:#0500FF;color:#ffffff;border-radius: 20px;display: flex;align-items: center;justify-content: center;}
  .content .act{background-color: #FF0000;}
  .listDiv{height:400px;margin-top:10px;overflow-y: auto;width:100%;}
  .listDiv .header{background-color: #1DF200;display: flex;align-items: center;width:100%;}
  .listDiv .header span{width:100%;font-size:14px;height:30px;display: flex;justify-content: center;align-items: center;}
  .listDiv .header span:nth-child(1){width:40%;}
  .listDiv .header span:nth-child(2){width:5%;justify-content: center;}
  .listDiv .header span:nth-child(3){width:40%;justify-content: center;}
  .listDiv .header span:nth-child(4){width:15%;justify-content: center;}
  .listDiv .list{display: flex;flex-direction: column;}
  .listDiv .list .item{background-color: #ffffff;display: flex;align-items: center;border:1px solid #1DF200;margin-top:2px;}
  .listDiv .list .item span{display: flex;align-items: center;padding:6px 0;}
  .listDiv .list .item span img{margin-right:10px;}
  .listDiv .list .item span b{width:16px;cursor: pointer;flex-shrink: 0;height:16px;border-radius: 50%;background-color: #D81E06;color:#ffffff;display: flex;align-items: center;justify-content: center;font-size:12px;margin-left:10px;}
  .listDiv .list .item span:nth-child(1){width:40%;padding-left:10px;}
  .listDiv .list .item span:nth-child(2){width:5%;justify-content: center;color:#0020C9;}
  .listDiv .list .item span:nth-child(3){width:40%;justify-content: center;color:#0020C9;}
  .listDiv .list .item span:nth-child(4){width:15%;justify-content: center;color:#D81E06;}
  .confirmWindow{position: fixed;top:0;left:0;width:100vw;height:100vh;display: flex;align-items:center;justify-content: center;}
  .confirmWindow .con{border:2px solid #00FF00;border-radius: 10px;background-color: #000000;width:600px;color:#ffffff;display: flex;flex-direction: column;padding:20px;align-items: center;}
  .confirmWindow .con p{font-size:26px;margin:30px 0;}
  .confirmWindow .con .close{width:100%;display: flex;justify-content: flex-end;}
  .confirmWindow .con .close b{width:24px;height:24px;font-size:14px;display: flex;justify-content: center;align-items: center;color:#4A4A4A;border:2px solid #D0D0D0;background-color: #ffffff;cursor: pointer;border-radius: 4px;}
  .confirmWindow .con .btn{width:120px;cursor: pointer;height: 40px;line-height:1;margin:10px 0 30px 0;background:#0500FF;color:#ffffff;border-radius: 8px;display: flex;align-items: center;justify-content: center;}
  a{text-decoration: none;color:#5C6BC0;}
  @media (max-width: 800px) {
    .pc{display: none !important;}
    .body{min-height:100vh;padding-top:60px;}
    .body *{font-size: 14px;}
    .title{margin:20px 0 6px 0 !important;font-size:24px !important;}
    .content{padding:0 0 10px 0 !important;}
    .content .info{width:100%;padding:20px;margin-top:0;}
    .listDiv{margin-top:10px;width:100%;}
    .list .item span b{margin-left:10px;}
    .listDiv .header span:nth-child(1){width:30%;}
    .listDiv .header span:nth-child(2){width:15%;}
    .listDiv .header span:nth-child(3){width:40%;}
    .listDiv .header span:nth-child(4){width:15%;}
    .listDiv .list .item span:nth-child(1){width:30%;}
    .listDiv .list .item span:nth-child(2){width:15%;}
    .listDiv .list .item span:nth-child(3){width:40%;}
    .listDiv .list .item span:nth-child(4){width:15%;}
    .confirmWindow .con{width:96vw;}
    .confirmWindow .con .listCon .list .item span:nth-child(2) img{width:18px;height:18px;}
    .confirmWindow .con .listCon .list .item span:nth-child(2) p{font-size:14px;}
    .confirmWindow .con .listCon .list .item span:nth-child(2) a{font-size:14px;}
    .content .btn{margin:10px auto;}
    .listDiv .list .item span img{width:18px;height:18px;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;min-height:calc(100vh - 206px);}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;min-height:calc(100vh - 206px);}
  }
</style>
