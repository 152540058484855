<template>
  <div class="body" v-loading="formLoading">
    <div class="content">
      <div class="title"><a>({{game_data.battle_type}})</a> {{game_data.title}}</div>
      <div class="info">
        <div class="itemp">
          <p>Rules</p>
        </div>
        <textarea style="height:80px;resize:none;" class="input" readonly v-model="game_data.rules"></textarea>
        <div class="itemp">
          <p>PVP type : {{game_data.pvp_type}} Match</p>
        </div>
        <div class="itemp">
          <p>Campaign period :  {{game_data.campaign_period_date_start}} ~ {{game_data.campaign_period_date_end}}</p>
        </div>
        <div class="tableTitle">
          <span></span>
          <p>PVP table</p>
          <span class="b" @click="show_score_board">Score board >></span>
        </div>
        <div class="listDiv">
          <div class="item header">
            <span>Team name</span>
            <span>
              <span v-for="item in game_data.number_of_battle_round">Round {{item}}</span>
            </span>
            <span>Score</span>
          </div>
          <div class="item" v-for="(item,index) in game_history" :key="index">
            <span class="user">
              <div class="imgs">
                <img :class="'headimg' + (user.user_status_web === '0'?' hui':'')" :src="user.user_profile_photo" v-if="user.user_profile_photo != null" v-for="user in item.team_members"/>
                <img :class="'headimg' + (user.user_status_web === '0'?' hui':'')" src="../assets/img/default_logo.jpg" v-else/>
              </div>
              <div class="userInfo">
                <a :class="item.user_status_web === '0'?'offline':item.user_status_web === '1'?'online':'onbattle'"> {{item.user_status_web === '0'?'offline':item.user_status_web === '1'?'online':'on-battle'}}</a>
                {{item.team_name}}
              </div>
              <span class="close" @click="cut_off(item.team_name, item.team_id)" v-if="isMaster">×</span>
            </span>
            <span>
              <span class="result" v-for="rd in item.round">
                <font v-if="rd.start_btn == null">{{rd.my_score}} : {{rd.other_score}}</font>
                <a :class="rd.isWins?'win':'lose'" v-if="rd.start_btn == null">{{rd.isWins?'win':'lose'}}</a>
                <button v-if="rd.start_btn && isLeader" @click="start_game(item.team_id)">Start</button>
              </span>
            </span>
            <span>{{item.rank_score}}</span>
          </div>

        </div>
      </div>
    </div>
    <div class="confirmWindow" v-if="isConfirm">
      <div class="con">
        <div class="close">
          <b @click="isConfirm = false">×</b>
        </div>
        <p>Are you sure to delete “{{select_name}}” ?</p>
        <div class="btn" @click="cut_off_go">Confirm</div>
      </div>
    </div>

    <div class="confirmWindow" v-if="scoreBoard">
      <div class="con">
        <div class="head">
          <a>　</a>
          <p>Score board</p>
          <b @click="scoreBoard = false">×</b>
        </div>
        <div class="list">
          <div class="item header">
            <span>Rank</span>
            <span>User</span>
            <span>Total round</span>
            <span>PVP score<a>(Win : Lose)</a></span>
            <span>Rank score</span>
          </div>
          <div class="item" v-for="(item,index) in score_datas">
            <span>{{item.rank}}</span>
            <span class="users">
              <div class="imgs qs" v-if="item.rank == 1">
                <img src="../assets/img/Vector1.png"/>
              </div>
              <div class="imgs qs" v-if="item.rank == 2">
                <img src="../assets/img/Vector2.png"/>
              </div>
              <p>{{item.team_name}}</p>
            </span>
            <span class="round">
              {{item.scores.number_of_games}}
              <a>{{item.scores.number_of_wins}} wins</a>
            </span>
            <span>{{item.scores.pvp_win}} : {{item.scores.pvp_lose}}</span>
            <span>{{item.scores.rank_score}}</span>
          </div>

        </div>
      </div>
    </div>

    <div class="confirmWindow" v-if="upload_layer_status">
      <div class="con" style="padding:10px 0;">
        <p style="margin: 10px 0;">Upload a picture of your inventory.</p>
        <p style="margin: 10px 0;">
          <upload
              class="avatar-uploader"
              :action="getUploadUrl"
              :headers="headers"
              :show-file-list="false"
              :multiple="false"
              accept="image/*"
              :on-success="handleAvatarSuccess"
              :before-upload="beforeAvatarUpload">
              <img class="headImg" style="width:75px;height:75px;" :src="upload_battle_image" v-if="upload_battle_image"/>
              <img class="headImg" style="width:75px;height:75px;" src="../assets/img/upload_b.png" v-else/>
            </upload>
        </p>
        <div class="btn" style="margin-top:15px;" @click="sub_upload_image">SUBMIT</div>
      </div>
    </div>

    <div class="confirmWindow" v-if="invitation_layer_status">
      <div class="con">
        <div class="close">
          <b @click="invitation_layer_status = false">×</b>
        </div>
        <p style="margin: 0;">Are you sure to start this battle ?</p>
        <p style="margin: 0;">Your request will be sent to the opponent.</p>
        <div class="btn" style="margin-top:25px;" @click="send_invitation">Agree</div>
      </div>
    </div>

    <div class="confirmWindow" v-if="invitation_layer_asked">
      <div class="con">
        <p style="margin: 0;">“{{game_data.title}}“</p>
        <p style="margin: 0;">The battle request has been arrived !</p>
        <div style="margin:10px 0;display: flex;justify-content:center;align-items:center;">
          <img class="headimg" :src="invitation_image" v-if="invitation_image" style="margin-right:15px;"/>
          <img class="headimg" style="margin-right:15px;" src="../assets/img/default_logo.jpg" v-else/>
          {{invitation_nickname}}
        </div>
        <p style="margin: 0;">Do you want to accept the request?</p>

        <div class="btnView" style="margin-top:25px;">
          <div class="btn warring" @click="response_invatation(2)">REJECT</div>
          <div class="btn" @click="response_invatation(1)">START</div>
        </div>
      </div>
    </div>

    <div class="confirmWindow" v-if="invitation_layer_wait">
      <div class="con">
        <p style="margin: 10px 0;">Waiting for the opponent accept...</p>
        <p style="margin: 10px 0;color: #00FF00;">{{show_time}}</p>
        <div class="btn warring" style="margin-top:25px;" @click="response_invatation(3)">CANCEL</div>
      </div>
    </div>

    <div class="confirmWindow" v-if="tip_status">
      <div class="con">
        <div class="close">
          <b @click="tip_status = false">×</b>
        </div>
        <p style="margin: 40px 0;">{{tip_content}}</p>
      </div>
    </div>

    <div class="confirmWindow" v-if="wait_layer">
      <div class="con">
        <p style="margin: 10px 0;">{{wait_content}}</p>
        <p style="margin: 10px 0;color: #00FF00;">{{show_time}}</p>
      </div>
    </div>

    <div class="confirmWindow" v-if="pkOne">
      <div class="con">
        <div class="head">
          <p>Battle start</p>
        </div>
        <div class="pkMany">
          <div class="items">
            <div class="item" v-for="item in battle_image_array.leftDatas">
              <div class="tis">
                <img :src="item.userProfilePhoto" v-if="item.userProfilePhoto"/>
                <img src="../assets/img/def_head.png" v-else/>
                {{item.nickname}}
              </div>
              <img class="gameImg" :src="item.battelImage"/>
            </div>
          </div>
          VS
          <div class="items items2">

            <div class="item" v-for="item in battle_image_array.rightDatas">
              <div class="tis">
                <img :src="item.userProfilePhoto" v-if="item.userProfilePhoto"/>
                <img src="../assets/img/def_head.png" v-else/>
                {{item.nickname}}
              </div>
              <img class="gameImg" :src="item.battelImage"/>
            </div>

          </div>
        </div>
        <div class="btnView" v-if="isLeader">
          <div class="btn warring" @click="battle_score_start(2)">REJECT</div>
          <div class="btn" @click="battle_score_start(1)">START</div>
        </div>
        <div v-else style="font-size: 22px;">
          <i class="el-icon-loading"></i>
          {{battle_show_content}}
        </div>
      </div>
    </div>

    <div class="confirmWindow" v-if="roundScore">
      <div class="con">
        <div class="head">
          <p>Enter the round score</p>
        </div>
        <div class="tils" style="margin: 0px 0 10px 0;">{{show_time}}</div>
        <div class="score">
          <div class="scoreuser" style="margin: 20px 0;" v-if="battle_score_obj.leftTeam">
            <img :src="battle_score_obj.leftTeam.userProfilePhoto" v-if="battle_score_obj.leftTeam.userProfilePhoto"/>
            <img src="../assets/img/def_head.png" v-else/>
            {{battle_score_obj.leftTeam.teamName}}
          </div>
          <div class="scoreuser" style="margin: 20px 0;" v-if="battle_score_obj.rightTeam">
            <img :src="battle_score_obj.rightTeam.userProfilePhoto" v-if="battle_score_obj.rightTeam.userProfilePhoto"/>
            <img src="../assets/img/def_head.png" v-else/>
            {{battle_score_obj.rightTeam.teamName}}
          </div>
        </div>

        <div class="score">
          <span class="scoreBtn" @click="focus_input(1)">
            <input v-model="team1_score" ref="team1_score_id" style="background-color: transparent;width:40px;text-align: center;height:40px;line-height: 40px;border:none;"/> wins
          </span>
          <span class="scoreBtn" @click="focus_input(2)">
            <input v-model="team2_score" ref="team2_score_id" style="background-color: transparent;width:40px;text-align: center;height:40px;line-height: 40px;border:none;"/> wins
          </span>
        </div>
        <div class="btnView">
          <div class="btn" style="margin-top:25px;" @click="submit_score">SUBMIT</div>
        </div>
      </div>
    </div>

    <div class="confirmWindow" v-if="roundScore_result">
      <div class="con">
        <div class="head">
          <p>Round score</p>
        </div>
        <div class="score">
          <div class="scoreuser" v-if="battle_score_obj.leftTeam">
            <img :src="battle_score_obj.leftTeam.userProfilePhoto" v-if="battle_score_obj.leftTeam.userProfilePhoto"/>
            <img src="../assets/img/def_head.png" v-else/>
            {{battle_score_obj.leftTeam.teamName}}
          </div>
          <div class="scoreuser" v-if="battle_score_obj.rightTeam">
            <img :src="battle_score_obj.rightTeam.userProfilePhoto" v-if="battle_score_obj.rightTeam.userProfilePhoto"/>
            <img src="../assets/img/def_head.png" v-else/>
            {{battle_score_obj.rightTeam.teamName}}
          </div>
        </div>
        <div class="tils">Your input</div>
        <div class="score">
          <span class="scoreBtn">{{my_team_score[0]}} wins</span>
          <span class="scoreBtn">{{my_team_score[1]}} wins</span>
        </div>
        <div class="dash"></div>
        <div class="tils">Opposite user input</div>
        <div class="score">
          <span class="scoreBtn scoreOk">{{opp_team_score[0]}} wins</span>
          <span class="scoreBtn scoreOk">{{opp_team_score[1]}} wins</span>
        </div>
        <br/>
        <div class="scoremsg" style="color: #0500FF;">The round result after battle must be the same.</div>
        <div class="btnView">
          <div class="btn" @click="roundScore_result = false">DONE</div>
        </div>
      </div>
    </div>

    <div class="confirmWindow" v-if="roundScore_result_fail">
      <div class="con">
        <div class="head">
          <p>Round score</p>
        </div>
        <div class="score">
          <div class="scoreuser" v-if="battle_score_obj.leftTeam">
            <img :src="battle_score_obj.leftTeam.userProfilePhoto" v-if="battle_score_obj.leftTeam.userProfilePhoto"/>
            <img src="../assets/img/def_head.png" v-else/>
            {{battle_score_obj.leftTeam.teamName}}
          </div>
          <div class="scoreuser" v-if="battle_score_obj.rightTeam">
            <img :src="battle_score_obj.rightTeam.userProfilePhoto" v-if="battle_score_obj.rightTeam.userProfilePhoto"/>
            <img src="../assets/img/def_head.png" v-else/>
            {{battle_score_obj.rightTeam.teamName}}
          </div>
        </div>
        <div class="tils">Your input</div>
        <div class="score">
          <span class="scoreBtn">{{my_team_score[0]}} wins</span>
          <span class="scoreBtn">{{my_team_score[1]}} wins</span>
        </div>
        <div class="dash"></div>
        <div class="tils">Opposite user input</div>
        <div class="score">
          <span class="scoreBtn scoreOk">{{opp_team_score[0]}} wins</span>
          <span class="scoreBtn scoreOk">{{opp_team_score[1]}} wins</span>
        </div>
        <br/>
        <div class="scoremsg">The score must be the same.</div>
        <div class="scoremsg" v-if="remaining_times !== '0'">You have {{remaining_times}} attempts remaining.</div>
        <div class="scoremsg" v-else>You don't have attempts remaining</div>

        <div class="btnView">
          <div class="btn warring" v-if="remaining_times !== '0'" @click="modify_score">MODIFY</div>
          <div class="btn warring" v-else @click="roundScore_result_fail = false">CLOSE</div>
        </div>
      </div>
    </div>

    <HashBottom></HashBottom>
  </div>
</template>
<script>
  import Vue from 'vue'
  import { postActionApi,getActionApi,deleteActionApi } from '@/network/manageApi'
  import { getAction,postAction } from '@/network/manage'
  import { mapGetters } from 'vuex'
  import { ACCESS_TOKEN } from '@/store/mutation-types'
  import { Upload } from 'element-ui'
  import HashBottom from "components/HashBottom"
  export default {
    name: 'GamePlayTeam',
    components: {
      HashBottom,Upload
    },
    data() {
      return {
        uuid: '',
        game_data: {number_of_battle_round:0},
        headers: {Authorization: this.$ls.get(ACCESS_TOKEN)},
        game_history: {},
        team_uuid: '',
        isMaster:false,
        isJoin:false,
        isLeader: false,
        isConfirm:false,
        scoreBoard:false,
        pkOne:false,
        pkMany:false,
        roundScore:false,
        formLoading:false,
        score_datas: [],
        websock:null,
        heartCheck:null,
        select_name: '',
        select_uuid: '',

        invitation_layer_status:false,
        invitation_layer_asked:false,
        invitation_layer_wait:false,
        tip_status: false,
        upload_layer_status:false,
        invitation_team_uuid: '',
        invitation_image:'',
        invitation_nickname:'',
        channel_id:'',
        tip_content:'',
        countdownObj:null,
        countdown_start_second:0,
        count_down_seconds: 0,
        show_time: '',
        timeout_method_type:undefined,
        upload_battle_image:'',
        wait_layer: false,
        wait_content: '',
        battle_show_content: '',
        battle_image_array: null,
        battle_score_obj: null,
        roundScore_result: false,
        team1_score:undefined,
        team2_score:undefined,
        roundScore_result_fail:false,
        remaining_times:0,
        my_team_score: ['*','*'],
        opp_team_score: ['*','*'],
      }
    },
    computed: {
      getUploadUrl() {
        return process.env.VUE_APP_API_URL + "/file-upload/upload"
      },
      ...mapGetters(['useruuid'])
    },
    beforeRouteLeave(to, from, next) {
      const answer = window.confirm('Incomplete game data will not be recorded. Are you sure to leave the current game?')
      if (answer) {
        if(this.websock) {
            this.websock.close()
        }
        this.heartCheck.reset()
        this.countdownObj.reset()
        next()
      } else {
        next(false)
      }
    },
    mounted() {
      window.onbeforeunload = function (e) {
        e = e || window.event;
        if (e) {
         e.returnValue = 'close';
        }
        return 'close';
       }
    },
    destroyed() {
      window.onbeforeunload = null
    },
    created(){
      this.$nextTick(() => {
        document.oncontextmenu = new Function("event.returnValue=false");
        document.addEventListener("keydown", function (e) {
          if (e.key == "F121" || e.key == "F5") {
            e.preventDefault();
          }
        });
      });

      if(!this.$route.query.uuid) {
        window.location.href='/'
      } else {
        this.uuid = this.$route.query.uuid
      }
      this.init()
      this.countdownFun();
    },
    methods:{
      submit_score() {
        if(isNaN(this.team1_score) || isNaN(this.team2_score)
        || !this.check_integer(this.team1_score)
        || !this.check_integer(this.team2_score)) {
          this.team1_score = ""
          this.team2_score = ""
          this.$message.error("Please enter the score");
          return
        }
        if(parseInt(this.team1_score) > this.game_data.round_win_first || parseInt(this.team1_score) < 0) {
          this.$message.error('Score must be greater than 0 and not greater than ' + this.game_data.round_win_first + '!');
          return;
        }
        if(parseInt(this.team2_score) > this.game_data.round_win_first || parseInt(this.team2_score) < 0) {
          this.$message.error('Score must be greater than 0 and not greater than ' + this.game_data.round_win_first + '!');
          return;
        }
        let obj = {"score1": this.team1_score,"score2": this.team2_score, "rlid": this.channel_id}
        this.formLoading = true;
        postAction("/user/battleScoringProcess",obj).then(res => {
          this.formLoading = false;
          if(res.code == 200){
            this.roundScore = false
            if(!this.roundScore_result && !this.roundScore_result_fail) {
                this.show_wait_tip("Waiting for the opponent enter the score", 300, 5)
            }
          }
        }).catch(err => {
          this.$message.warning(err.message);
          this.formLoading = false;
        })
      },
      check_integer(num) {
        return /^\d+$/.test(num);
      },
      focus_input(t) {
        if(t == 1) {
          this.$refs.team1_score_id.focus()
        } else {
          this.$refs.team2_score_id.focus()
        }
      },
      modify_score() {
        this.roundScore_result_fail = false
        this.show_score_layer();
      },
      battle_score_start(t) {
        let obj = {"t": t, "rlid": this.channel_id}
        this.formLoading = true;
        postAction("/user/confirmBattle",obj).then(res => {
          this.formLoading = false;
          if(res.code == 200){
            if(t == 1) {
              this.pkOne = false
              if(!this.roundScore) {
                  this.show_wait_tip("Wating for the score input...", 300, 3)
              }
            }
          }
        }).catch(err => {
          this.$message.warning(err.message);
          this.formLoading = false;
        })
      },
      show_pk_layer() {
        this.pkOne = true
        this.formLoading = true
        this.battle_image_array = {}
        getAction("/user/showBattleImageList?rlid=" + this.channel_id).then(res => {
          this.formLoading = false;
          if(res.code == 200){
            this.battle_image_array = res.result
          }
        })
      },
      show_score_layer() {
        this.roundScore = true
        this.formLoading = true
        this.battle_score_obj = {}
        getAction("/user/showBattleTeamInfo?rlid=" + this.channel_id).then(res => {
          this.formLoading = false;
          if(res.code == 200){
            this.battle_score_obj = res.result
            this.count_down_seconds = 3600
            this.countdown_start_second = this.get_now_second()
            this.calculate_remaining_time(this.count_down_seconds)
            this.timeout_method_type = 4
            this.countdownObj.reset().start()
          }
        })
      },
      sub_upload_image() {
        if(!this.upload_battle_image) {
          this.$message.warning("Please upload your inventory image")
          return;
        }
        let obj = {"imageUrl": this.upload_battle_image, "rlid": this.channel_id}
        this.formLoading = true;
        postAction("/user/submitWarImage",obj).then(res => {
          this.formLoading = false;
          if(res.code == 200){
            this.upload_layer_status = false
            this.upload_battle_image = ""
            if(!this.pkOne) {
                this.show_wait_tip("Wating for the opponent upload...", res.result.time, 2)
            }
          } else {
            this.$message.warning(res.message);
          }
        }).catch(err => {
          this.$message.warning(err.message);
          this.formLoading = false;
        })
      },
      send_invitation() {
        if(this.websock.readyState != 1) {
          this.$message.warning("Game disconnection, Please refresh and reconnect");
          return;
        }
        this.formLoading = true;
        let obj = {"gameUuid": this.uuid, "teamUuid": this.invitation_team_uuid}
        postAction("/user/invitationWar",obj).then(res => {
          this.formLoading = false;
          if(res.code == 200){
            this.invitation_layer_wait = true
            this.invitation_layer_status = false
            this.channel_id = res.result.requestLogUuid
            this.count_down_seconds = 300
            this.calculate_remaining_time(this.count_down_seconds)
            this.countdown_start_second = this.get_now_second();
            this.timeout_method_type = 1
            this.countdownObj.reset().start()
          } else {
            this.$message.warning(res.message);
          }
        }).catch(err => {
          this.$message.warning(err.message);
          this.formLoading = false;
        })
      },
      response_invatation(type) {
        let obj = {"t": type, "rlid": this.channel_id}
        this.formLoading = true;
        postAction("/user/invitationWarProcessing",obj).then(res => {
          this.formLoading = false;
          if(res.code == 200){
            if(type == 2) {
                this.invitation_layer_asked = false
                this.channel_id = ""
            } else if(type == 3) {
                this.invitation_layer_wait = false
                this.channel_id = ""
                //清空定时任务
                this.timeout_method_type = undefined
                this.count_down_seconds = 0
                this.countdownObj.reset()
            } else {
                //打开一个等待链接层
                this.invitation_layer_asked = false
                if(!this.upload_layer_status) {
                    this.show_tip("Connecting")
                }
            }
          } else {
            this.invitation_layer_asked = false
            // this.$message.warning(res.message);
          }
        }).catch(err => {
          this.$message.warning(err.message);
          this.formLoading = false;
        })
      },
      start_game(team_id) {
        this.invitation_team_uuid = team_id
        this.invitation_layer_status = true
      },
      cut_off(nickname, uuid) {
        this.isConfirm = true
        this.select_name = nickname
        this.select_uuid = uuid
      },
      cut_off_go() {
        deleteActionApi("/game-management/join-game?team_id=" + this.select_uuid).then(res => {
          this.formLoading = false;
          if(res.code == 200){
            this.$message.success("cut off successfully~");
            this.init()
          } else {
            this.$message.warning(res.message);
            this.formLoading = false;
          }
        }).catch(err => {
          this.$message.warning(err.message);
          this.formLoading = false;
        })
      },
      init() {
        this.isConfirm = false
        this.formLoading = true
        getActionApi('/game-management/game-detail?game_uuid=' + this.uuid).then(res => {
          if (res.code == 200) {
            this.game_data = res.data.contents
            if(this.game_data.team_detail.length == 0) {
              window.location.href='/'
            }
            //状态判断 started 跳转到比赛  end 去首页,判断游戏类型
            if(this.game_data.pvp_type == 'Solo') {
              if(this.game_data.game_status == 'waiting') {
                window.location.href='/joinMatch?uuid=' + this.uuid
              } else if(this.game_data.game_status == 'end') {
                window.location.href='/'
              } else {
                window.location.href='/gamePlayMatch?uuid=' + this.uuid
              }
            } else {
              if(this.game_data.game_status == 'waiting') {
                window.location.href='/joinTeam?uuid=' + this.uuid
              } else if(this.game_data.game_status == 'end') {
                window.location.href='/'
              }
            }


            if(this.useruuid == this.game_data.user_uuid) {
              this.isMaster = true
            }
            for(let item of this.game_data.team_detail) {
              for(let member of item.members) {
                if(this.useruuid == member.user_uuid) {
                  this.isJoin = true
                  this.team_uuid = item.team
                  this.isLeader = member.team_leader
                }
              }
            }
            if(!this.isJoin) {
              window.location.href='/'
            }

            getActionApi('/game-management/my-game-history?game_uuid=' + this.uuid).then(res => {
              if (res.code == 200) {
                this.game_history = res.data.contents
                let i = 0
                let remove_id
                for(let item of this.game_history) {
                  if(this.team_uuid == item.team_id) {
                    remove_id = i
                  }
                  i++;
                  //默认不在线 0 不在线， 1在线 2战斗中
                  item.user_status_web = '0'
                  for(let user of item.team_members) {
                    user.user_status_web = '0'
                    if(user.team_leader == 1) {
                      if(user.team_name != null && user.team_name != "") {
                        item.team_name = user.team_name
                      }
                    }
                  }
                  for(let r of item.round) {
                    r.isWins = false
                    if(r.winner == this.team_uuid) {
                      r.isWins = true
                    }
                    if(r.team1.team_id == this.team_uuid) {
                      r.my_score = r.team1.score
                      r.other_score = r.team2.score
                    } else {
                      r.my_score = r.team2.score
                      r.other_score = r.team1.score
                    }
                  }
                  let number = this.game_data.number_of_battle_round - item.round.length
                  if(number > 0) {
                    //需要补数据
                    for(let i=0;i<number;i++) {
                      let obj = {}
                      if(i == 0) {
                        obj.start_btn = true
                      } else {
                        obj.start_btn = false
                      }
                      item.round.push(obj)
                    }
                  }
                }
                this.game_history.splice(remove_id, 1)
                this.initWebSocket()
                this.heartCheckFun()
                this.formLoading = false
              }
            })
          } else {
            window.location.href='/'
          }
        })
      },
      show_score_board() {
        this.formLoading = true
        getActionApi('/game-management/campaign-ranking?game_uuid=' + this.uuid).then(res => {
          if (res.code == 200) {
            this.score_datas = res.data.contents
            for(let item of this.score_datas) {
              let point = (item.scores.pvp_win - item.scores.pvp_lose) > 0?(item.scores.pvp_win - item.scores.pvp_lose):0
              item.scores.rank_score = item.scores.number_of_wins * point
            }
            this.scoreBoard = true
            this.formLoading = false
          }
        })
      },
      check_integer(num) {
        return /^\d+$/.test(num);
      },
      show_wait_tip(content, time, type) {
        this.wait_layer = true
        this.wait_content = content
        this.count_down_seconds = time
        this.calculate_remaining_time(this.count_down_seconds)
        this.countdown_start_second = this.get_now_second()
        this.timeout_method_type = type
        this.countdownObj.reset().start()
      },
      show_tip(content) {
        this.tip_status = true
        this.tip_content = content
      },
      calculate_remaining_time(remainingTime) {
        let fz = parseInt(remainingTime/60);
        let mz = parseInt(remainingTime%60);
        this.show_time = (fz<10?("0" + fz):fz) + ":" + (mz<10?("0" + mz):mz)
      },
      get_now_second() {
        const now = new Date()
        return Math.floor(now.getTime() / 1000)
      },
      initWebSocket: function () {
        var url = process.env.VUE_APP_WEB_SOCKET_URL + "/connection/" + Vue.ls.get(ACCESS_TOKEN) + "/" + this.uuid + "/" + this.get_now_second();
        this.websock = new WebSocket(url);
        this.websock.onopen = this.websocketOnopen;
        this.websock.onerror = this.websocketOnerror;
        this.websock.onmessage = this.websocketOnmessage;
        this.websock.onclose = this.websocketOnclose;
      },
      websocketOnopen: function () {
        this.$notification({
          title: 'TIP',
          message: 'Successfully connected to the game',
          type: 'success'
        });
        this.heartCheck.reset().start();
      },
      websocketOnerror: function (e) {
        this.$notification.error({
          title: 'TIP',
          message: 'Game disconnected,Please refresh and reconnect',
          duration: 0
        });
      },
      websocketOnmessage: function (e) {
        console.log("-----服务器下发的消息处理-------",e.data);
        var data = eval("(" + e.data + ")");
        if(data.cmd == "status"){
            //在线状态变动
            let datas = data.data
            for(let user_status of datas) {
              for(let i = 0;i < this.game_history.length;i++) {
                if(this.game_history[i].team_members != undefined) {
                  for(let j = 0;j < this.game_history[i].team_members.length;j++) {
                    if(user_status.userUuid == this.game_history[i].team_members[j].user_uuid) {
                      let item = this.game_history[i];
                      item.team_members[j].user_status_web = user_status.status
                      if(this.game_history[i].team_members[j].team_leader == 1) {
                        item.user_status_web = user_status.status
                      }
                      this.$set(this.game_history, i, item)
                    }
                  }
                }
              }
            }
        } else if(data.cmd == "notification_invitation_launch") {
          //发起邀请命令
          if(!this.channel_id) {
            let d = data.data
            this.invitation_layer_asked = true
            this.invitation_image = d.userProfilePhoto
            this.invitation_nickname = d.nickName
            this.channel_id = d.requestLogUuid
            this.invitation_team_uuid = d.teamUuid
          }
        } else if(data.cmd == "invitation_battle_end") {
          //邀请中断-离线
          this.invitation_layer_asked = false
          this.invitation_layer_wait = false
          this.channel_id = ""
          this.invitation_image = ""
          this.invitation_nickname = ""
          this.timeout_method_type = undefined
          this.count_down_seconds = 0
          this.countdownObj.reset()
          this.show_tip('Sorry, your battle request was canceled.')
        } else if(data.cmd == "reject_invitation_battle") {
          //拒绝邀请命令
          let d = data.data
          if(this.channel_id == d.rlid) {
            this.invitation_layer_wait = false
            this.channel_id = ""
            this.timeout_method_type = undefined
            this.count_down_seconds = 0
            this.countdownObj.reset()
            this.show_tip("Sorry, your battle request was rejected.")
          }
        } else if(data.cmd == "cancel_invitation_battle") {
          //取消邀请命令
          let d = data.data
          if(this.channel_id == d.rlid) {
            this.invitation_layer_asked = false
            this.channel_id = ""
            this.invitation_image = ""
            this.invitation_nickname = ""
            this.countdownObj.reset()
          }
        } else if(data.cmd == "agree_invitation_battle") {
          //上传图命令
          let d = data.data
          if(this.channel_id == d.rlid || !this.channel_id) {
            this.tip_status = false
            this.upload_battle_image = ''
            this.upload_layer_status = true
            this.invitation_layer_wait = false
            this.timeout_method_type = undefined
            this.count_down_seconds = 0
            this.channel_id = d.rlid
            this.countdownObj.reset()
          }
        } else if(data.cmd == "upload_image_timeout") {
          //上传图超时命令
          let d = data.data
          if(this.channel_id == d.rlid) {
            this.wait_layer = false
            this.upload_layer_status = false
            this.upload_battle_image = ''
            this.channel_id = ""
            this.show_tip("Upload timeout,the game is over")
            this.countdownObj.reset()
          }
        } else if(data.cmd == "battle_upload_completed") {
          //图片上传完的指令
          let d = data.data
          if(this.channel_id == d.rlid) {
            //关闭之前的
            this.wait_layer = false
            //打开队伍图展示界面
            this.show_pk_layer()
            this.countdownObj.reset()
            //队员展示的
            this.battle_show_content = "Both captains are scoring,please wait."
          }
        } else if(data.cmd == "wait_start_timeout") {
          //开始打分的超时指令
          let d = data.data
          if(this.channel_id == d.rlid) {
            this.channel_id = ""
            this.wait_layer = false
            this.pkOne = false
            this.show_tip("Waiting timeout,the game is over")
            this.countdownObj.reset()
          }
        } else if(data.cmd == "refuse_score_battle") {
          //开始打分的决绝指令
          let d = data.data
          if(this.channel_id == d.rlid) {
            this.channel_id = ""
            this.wait_layer = false
            this.pkOne = false
            this.show_tip("Player refuses,the game is over")
            this.countdownObj.reset()
          }
        } else if(data.cmd == "start_score_battle") {
          //开始打分指令
          let d = data.data
          if(this.channel_id == d.rlid && this.isLeader) {
            this.wait_layer = false
            this.show_score_layer();
          }
        } else if(data.cmd == "score_battle_timeout") {
          //打分超时（1小时）指令
          let d = data.data
          if(this.channel_id == d.rlid) {
            this.wait_layer = false
            this.roundScore = false
            this.pkOne = false
            this.channel_id = ""
            this.show_tip("Player score timeout,the game is over")
            this.countdownObj.reset()
          }
        } else if(data.cmd == "wait_score_battle_timeout") {
          //打分超时（罚分）指令
          let d = data.data
          if(this.channel_id == d.rlid) {
            this.wait_layer = false
            this.roundScore = false
            this.pkOne = false
            this.channel_id = ""
            this.show_tip("Player score timeout,the game is over")
            this.countdownObj.reset()
          }
        } else if(data.cmd == "complete_scores_tip") {
          //打分完结指令
          let d = data.data
          if(this.channel_id == d.rlid) {
            if(this.isLeader) {
              this.my_team_score = d[this.team_uuid].split(":")
              this.opp_team_score = d[this.invitation_team_uuid].split(":")
              this.channel_id = ""
              this.countdownObj.reset()
              this.wait_layer = false
              this.roundScore_result = true
              this.team1_score = ""
              this.team2_score = ""
            } else {
              this.channel_id = ""
              this.pkOne = false
              this.countdownObj.reset()
              this.show_tip("Scoring completed, game completed")
            }
          }
          //刷新列表
          this.isConfirm = false
          this.formLoading = true
          getActionApi('/game-management/my-game-history?game_uuid=' + this.uuid).then(res => {
            if (res.code == 200) {
              let tempList = res.data.contents
              for(let game of this.game_history) {
                for(let item of tempList) {
                  if(item.team_id == game.team_id) {
                    game.round = item.round
                    break;
                  }
                }
              }
              for(let item of this.game_history) {
                for(let r of item.round) {
                  r.isWins = false
                  if(r.winner == this.team_uuid) {
                    r.isWins = true
                  }
                  if(r.team1.team_id == this.team_uuid) {
                    r.my_score = r.team1.score
                    r.other_score = r.team2.score
                  } else {
                    r.my_score = r.team2.score
                    r.other_score = r.team1.score
                  }
                }
                let number = this.game_data.number_of_battle_round - item.round.length
                if(number > 0) {
                  //需要补数据
                  for(let i=0;i<number;i++) {
                    let obj = {}
                    if(i == 0) {
                      obj.start_btn = true
                    } else {
                      obj.start_btn = false
                    }
                    item.round.push(obj)
                  }
                }
              }
              this.formLoading = false
            }
          })

        } else if(data.cmd == "uneven_scores_tip") {
          //打分不一致指令
          let d = data.data
          if(this.channel_id == d.rlid) {
            if(this.isLeader) {
              this.my_team_score = d[this.team_uuid].split(":")
              this.opp_team_score = d[this.invitation_team_uuid].split(":")
              this.remaining_times = d.times
              this.wait_layer = false
              this.countdownObj.reset()
              this.roundScore_result_fail = true
              if(d.times === "0") {
                this.channel_id = ""
                this.team1_score = ""
                this.team2_score = ""
              }
            } else {
              if(d.times === "0") {
                this.channel_id = ""
                this.pkOne = false
                this.countdownObj.reset()
                this.show_tip("The score input does not match, the game is over")
              }
            }
          }
        } else if(data.cmd == "disconnect_battle_end") {
          //玩家离线指令
          let d = data.data
          if(this.channel_id == d.rlid) {
            this.pkOne = false
            this.roundScore = false
            this.invitation_layer_status = false
            this.invitation_layer_asked = false
            this.invitation_layer_wait = false
            this.tip_status = false
            this.upload_layer_status = false
            this.wait_layer = false
            this.roundScore_result = false
            this.roundScore_result_fail = false
            this.countdownObj.reset()
            this.channel_id = ""
            this.team1_score = undefined
            this.team2_score = undefined
            this.show_tip("Some players are offline,the game is over")
          }
        }
        //心跳检测重置
        this.heartCheck.reset().start();
        this.formLoading = false
      },
      websocketOnclose: function (e) {
        if(e.code != 1000) {
          this.$notification.error({
            title: 'TIP',
            message: 'Game disconnected,Please refresh and reconnect',
            duration: 0
          });
        }
      },
      websocketSend(text) {
        try {
          this.websock.send(text);
        } catch (err) {
          console.log("send failed (" + err.code + ")");
        }
      },
      heartCheckFun(){
        var that = this;
        that.heartCheck = {
          timeout: 60000,
          timeoutObj: null,
          serverTimeoutObj: null,
          reset: function(){
            clearTimeout(this.timeoutObj);
            return this;
          },
          start: function(){
            var self = this;
            this.timeoutObj = setTimeout(function(){
              that.websocketSend("ping");
            }, this.timeout)
          }
        }
      },
      countdownFun(){
        var that = this;
        that.countdownObj = {
          time: 1000,
          cdTimeObj: null,
          reset: function(){
            clearTimeout(this.cdTimeObj);
            return this;
          },
          start: function(){
            var self = this;
            this.cdTimeObj = setTimeout(function(){
              let now = that.get_now_second();
              let sy_seconds = that.count_down_seconds - (now - that.countdown_start_second);
              if(sy_seconds >= 0) {
                  that.calculate_remaining_time(sy_seconds)
                  that.countdownObj.reset().start()
              } else {
                if(that.timeout_method_type == 1) {
                  that.response_invatation(3)
                } else if(that.timeout_method_type == 2) {
                  that.timeout_upload_image_reporting()
                } else if(that.timeout_method_type == 3) {
                  that.timeout_wait_score_confirm()
                } else if(that.timeout_method_type == 4) {
                  that.timeout_wait_scoring_one_hour()
                } else if(that.timeout_method_type == 5) {
                  that.timeout_wait_scoring_punish()
                }
              }
            }, this.time)
          }
        }
      },
      timeout_wait_scoring_punish() {
        let obj = {"t": 0, "rlid": this.channel_id}
        postAction("/user/waitScoreBattleTimeout",obj)
      },
      timeout_wait_scoring_one_hour() {
        let obj = {"t": 0, "rlid": this.channel_id}
        postAction("/user/scoreBattleTimeout",obj)
      },
      timeout_wait_score_confirm() {
        this.wait_layer = false
        let obj = {"t": 0, "rlid": this.channel_id}
        postAction("/user/waitBattleStartTimeout",obj)
      },
      timeout_upload_image_reporting() {
        this.wait_layer = false
        let obj = {"t": 0, "rlid": this.channel_id}
        postAction("/user/uploadBattleImageTimeout",obj)
      },
      handleAvatarSuccess(res, file) {
        this.loding.close()
        getActionApi('/file-upload/display?user_uuid=' + this.useruuid + '&image_name=' + res.data.upload_path).then(result => {
          if (result.code == 200) {
              this.upload_battle_image = result.data.url
          }
        })
      },
      beforeAvatarUpload(file) {
        const isLt5M = file.size / 1024 / 1024 < 10;
        if (!isLt5M) {
          this.$message.error('Upload avatar size cannot exceed 10MB!')
        } else {
          this.loding = this.$loading.service()
        }
        return isLt5M
      }
    }
  }
</script>

<style scoped>
  .swiper-slide{display:flex;}
  *{font-size:16px;font-family: 'ABeeZee';font-size:inherit;box-sizing: border-box;margin:0px;padding:0px;}
  input,textarea{outline: none;}
  .body{text-align: center;}
  .title{font-size: 24px;color:#00FF00;margin-bottom:30px;}
  .title a{color:#657EFF;font-size: 24px;}
  .userInfo{overflow: hidden;}
  .headimg {width:30px;height:30px;border-radius: 50%;border:1px #c1c1c1 solid;flex-shrink: 0;}
  .content{display: flex;flex-direction: column;margin:auto;align-items: center;padding:30px 0 0 0;}
  .content .info{width:600px;display: flex;flex-direction: column;align-items: center;}
  .content .info hr{width:100%;height:0px;border:none;border-bottom:1px dashed #00FF00;margin:20px 0 6px 0;}
  .content .itemp{width:100%;display: flex;justify-content: flex-start;margin-top:4px;}
  .content .itemp p{width:100%;text-align: left;color:#ffffff;}
  .content .input{width:100%;min-height:30px;}
  .content .btn{width:160px;cursor: pointer;height: 40px;line-height:1;margin:10px 0 30px 0;background:#0500FF;color:#ffffff;border-radius: 20px;display: flex;align-items: center;justify-content: center;}
  .content .act{background-color: #FF0000;}
  .tableTitle{margin-top:40px;color:#ffffff;display: flex;align-items: center;width:100%;margin-bottom:10px;}
  .tableTitle p{width:100%;font-style: italic;}
  .tableTitle span{width:140px;flex-shrink: 0;}
  .tableTitle .b{background-color: #0500FF;display: flex;align-items: center;justify-content: center;cursor: pointer;height:30px;}
  .listDiv{max-height:400px;overflow-y: auto;width:100%;}
  .listDiv .item{background-color: #FFFFFF;display: flex;align-items: center;width:100%;margin-bottom:2px;}
  .listDiv .item span{width:100%;font-size:14px;height:46px;display: flex;justify-content: center;align-items: center;}
  .listDiv .item>span:nth-child(1){width:35%;}
  .listDiv .item>span:nth-child(2){width:46%;display: flex;justify-content:space-around;align-items:center;}
  .listDiv .item>span:nth-child(3){width:19%;}
  /* .listDiv .item span:nth-child(4){width:17%;} */
  /* .listDiv .item span:nth-child(5){width:19%;} */
  .listDiv button{color:#ffffff;width:70px;height:34px;display: flex;align-items: center;justify-content: center;border-radius: 18px;background-color: #0500FF;border: none;cursor: pointer;}
  .listDiv .result{flex-direction: column;align-items: center;}
  .listDiv .result a{font-size:12px;line-height: 1;}
  .listDiv .result .win{color:#0500FF;}
  .listDiv .result .lose{color:#FF0000;}
  .listDiv .user{justify-content: flex-start;padding-left:20px;flex-shrink: 0;}
  .listDiv .user div{display: flex;flex-direction: column;width:100%;align-items: flex-start;font-size:13px;}
  .listDiv .user div a{font-size:12px;line-height: 1;}
  .listDiv .user div .online{color:#1DF200;}
  .listDiv .user div .offline{color:#828282;}
  .listDiv .user div .onbattle{color:#FF0000;}
  .listDiv .user .imgs{display: flex;align-items: center;flex-direction: row;flex-shrink: 0;width:auto;margin-right:10px;}
  .listDiv .user .imgs img{border:3px solid #ffffff;box-shadow: 0px 4px 6px -1px rgba(0, 0, 0, 0.25);border-radius: 50%;width:30px;height:30px;flex-shrink: 0;}
  .listDiv .user .imgs img:nth-child(2){margin-left:-15px;}
  .listDiv .user .imgs img:nth-child(3){margin-left:-15px;}
  .listDiv .user .imgs img:nth-child(4){margin-left:-15px;}
  .listDiv .user .close{flex-shrink: 0;width:16px !important;height: 16px !important;color:#ffffff;background-color:#D81E06;border-radius: 50%;cursor: pointer;font-size:12px;}
  .listDiv .header{background-color: #1DF200;}
  .listDiv .header span{justify-content: center !important;padding-left:0 !important;}
  .hui{filter: grayscale(100%);border-color:#C4C4C4 !important;}
  .confirmWindow{position: fixed;top:0;left:0;width:100vw;height:100vh;display: flex;align-items:center;justify-content: center;}
  .confirmWindow .con{border:2px solid #00FF00;border-radius: 10px;background-color: #000000;min-width:600px;color:#ffffff;display: flex;flex-direction: column;padding:20px;align-items: center;}
  .confirmWindow .con p{font-size:26px;margin:30px 0;}
  .confirmWindow .con .close{width:100%;display: flex;justify-content: flex-end;}
  .confirmWindow .con .close b{width:24px;height:24px;font-size:14px;display: flex;justify-content: center;align-items: center;color:#4A4A4A;border:2px solid #D0D0D0;background-color: #ffffff;cursor: pointer;border-radius: 4px;}
  .confirmWindow .con .btn{width:120px;cursor: pointer;height: 40px;line-height:1;margin:10px 20px 30px 20px;background:#0500FF;color:#ffffff;border-radius: 8px;display: flex;align-items: center;justify-content: center;}
  .confirmWindow .con .head{width:100%;display: flex;justify-content: flex-end;align-items: center;}
  .confirmWindow .con .head p{color:#ffffff;font-size:20px;text-align: center;width:100%;margin:0;}
  .confirmWindow .con .head a{width:24px;flex-shrink: 0;}
  .confirmWindow .con .head b{width:24px;flex-shrink: 0;height:24px;font-size:14px;display: flex;justify-content: center;align-items: center;color:#4A4A4A;border:2px solid #D0D0D0;background-color: #ffffff;cursor: pointer;border-radius: 4px;}
  .confirmWindow .list{max-height: 380px;margin:20px 0;overflow-y: auto;width:100%;}
  .confirmWindow .list .item{display: flex;align-items: center;background-color: #ffffff;margin-bottom: 2px;color:#000000;height:44px;}
  .confirmWindow .list .item:last-child{margin-bottom:0;}
  .confirmWindow .list .item span:nth-child(1){width:12%;}
  .confirmWindow .list .item span:nth-child(2){width:28%;}
  .confirmWindow .list .item span:nth-child(3){width:20%;}
  .confirmWindow .list .item span:nth-child(4){width:20%;}
  .confirmWindow .list .item span:nth-child(5){width:20%;}
  .confirmWindow .list .users{display: flex;align-items: center;flex-direction: column;}
  .confirmWindow .list .users .imgs{flex-shrink: 0;display: flex;align-items: center;flex-direction: column;justify-content: center;}
  .confirmWindow .list .users p{font-size:16px;width:100%;margin:0;line-height:1;}
  .confirmWindow .list .round{flex-direction: column;display: flex;line-height: 1;}
  .confirmWindow .list .round a{color:#0500FF;font-size:12px;margin-top:5px;}
  .confirmWindow .header{background-color:#00FF00 !important;}
  .confirmWindow .header span{display: flex;flex-direction: column;align-items: center;justify-content: center;line-height: 1;}
  .confirmWindow .header span a{font-size:12px;color:#000000;line-height: 1;}
  .confirmWindow .btnView{display: flex;justify-content: center;align-items: center;}
  .confirmWindow .warring{background-color: #FF0000 !important;}
  .pkOne{display: flex;align-items: center;color: #00FF00;font-size:40px;margin:30px 0 10px 0;width:700px;justify-content: space-between;}
  .pkOne .item{display: flex;flex-direction: column;align-items: center;}
  .pkOne .item .tis{display: flex;align-items: center;font-size:16px;color:#ffffff;}
  .pkOne .item .tis img{width:30px;height:30px;margin-right:10px;border:3px solid #ffffff;border-radius: 50%;}
  .pkOne .item .gameImg{width:260px;height:260px;}
  .pkMany{display: flex;align-items: center;color: #00FF00;font-size:40px;margin:30px 0 10px 0;width:700px;justify-content: space-between;}
  .pkMany .items{display: flex;width:270px;flex-wrap: wrap;height:400px;flex-direction: column;align-items: center;justify-content: center;overflow: auto;}
  .pkMany .items2{flex-wrap: wrap-reverse !important;}
  .pkMany .item{display: flex;flex-direction: column;align-items: center;margin:5px;width:120px;overflow: hidden;}
  .pkMany .item .tis{display: flex;align-items: center;font-size:16px;color:#ffffff;}
  .pkMany .item .tis img{width:30px;height:30px;margin-right:10px;border:3px solid #ffffff;border-radius: 50%;flex-shrink: 0;}
  .pkMany .item .gameImg{width:100px;height:100px;margin-top: 10px;}
  .score{display: flex;justify-content: space-between;width:80%;align-items: center;}
  .score .scoreuser{display: flex;justify-content: center !important;align-items: center !important;width:200px;margin-top:20px;}
  .score .scoreuser img{width:30px;height:30px;margin-right:10px;border:3px solid #ffffff;border-radius: 50%;flex-shrink: 0;}
  .score .scoreBtn{width:200px;height:40px;border-radius: 10px;background-color: #D9D9D9;display: flex;align-items: center;justify-content: center;color:#000000;}
  .score .scoreOk{background-color: #00FF00 !important;}
  .confirmWindow .scoremsg{color:#FF0000;}
  .confirmWindow .tils{color:#00FF00;margin-bottom:10px;}
  .confirmWindow .dash{border-bottom:2px dashed #00FF00;width:80%;margin:40px 0 10px 0;height:2px;}
  a{text-decoration: none;color:#5C6BC0;}
  @media (max-width: 800px) {
    .pc{display: none !important;}
    .body{min-height:100vh;padding-top:60px;}
    .body *{font-size:14px;}
    .content{padding:0 0 30px 0 !important;}
    .content .info{width:100%;padding:20px;margin-top:0;}
    .listDiv .user{padding-left:0px;flex-shrink: 0;justify-content: left !important;}
    .listDiv .user .imgs{margin-right:3px;}
    .listDiv .user img{width:24px;height:24px;}

    .listDiv button{width:60px;height:30px;}
    .title{font-size: 20px;margin:16px 0;}
    .confirmWindow .con{width:96vw;min-width:0;}
    .confirmWindow .list .users{flex-direction: column;}
    .confirmWindow .list .users .imgs{height:14px;margin-right:0px;}
    .confirmWindow .list .users .imgs img{height:14px;}
    .confirmWindow .list .users .imgs img:nth-child(2){margin-top:0;}
    .confirmWindow .list .users p{line-height:1;font-size: 14px;}
    .confirmWindow .list .qs{padding-bottom:0;}
    .pkOne{font-size:24px;width:100%;}
    .pkOne .item .tis img{width:24px;height:24px;}
    .pkOne .item .gameImg{width:36vw;height:36vw;}
    .confirmWindow .con .btn{width:100px;height: 36px;margin: 10px 20px;}
    .pkMany{font-size:24px;width:100%;}
    .pkMany .items{width:36vw;height:56vw;}
    .pkMany .item{margin:5px;width:16vw;overflow: hidden;}
    .pkMany .item .tis{font-size:12px;width:100%;}
    .pkMany .item .tis img{width:20px;height:20px;margin-right: 5px;}
    .pkMany .item .gameImg{width:16vw;height:16vw;}

    .score{width:100%;}
    .score .scoreuser{width:40vw;}
    .score .scoreuser img{width:24px;height:24px;}
    .score .scoreBtn{width:40vw;}
    .confirmWindow .dash{width:100%;margin:30px 0 10px 0;}
  }
  @media screen and (min-width: 800px) and (max-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:800px;min-height:calc(100vh - 206px);}
  }
  @media(min-width: 1200px){
  	.mobile{display: none !important;}
    .content{width:1200px;min-height:calc(100vh - 206px);}
  }
</style>
