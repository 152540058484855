<template>
  <div id="app">
    <hash-header></hash-header>
    <router-view/>
  </div>
</template>

<script>
  import HashHeader from "components/HashHeader"
  export default {
    name: 'App',
    components: {
      HashHeader,
    },
    created() {
      let that = this
    }
  }
</script>

<style>
	@import "./assets/css/base.css";
  #app{background-color: #000000;box-sizing: border-box;min-height:100vh;}
  @media (max-width: 800px) {
    #app{background-color: #452D2D;}
    .el-message {
      min-width: 300px !important;
    }
    .el-message-box{
      width: 80vw !important;
    }
  }
  .el-loading-mask {
    background-color: rgba(0, 0, 0, 0.8) !important;
  }
  @media screen and (min-width: 800px) and (max-width: 1200px) {
  	#app{padding-top:80px;padding-bottom:60px;}
  }
  @media(min-width: 1200px) {
  	#app{padding-top:80px;padding-bottom:60px;}
  }
</style>
