import Vue from 'vue'
import router from './router'
import store from './store'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import { MessageBox,Notification,Loading } from 'element-ui'

NProgress.configure({ showSpinner: false })

const whiteList = ['/404',
                    '/' ,
                    '/login',
                    '/register',
                    '/findpw']

router.beforeEach((to, from, next) => {
  NProgress.start()
  if (store.getters.token) {
    /* has token */
    if (to.path === '/register') {
      next({ path: '/' })
      NProgress.done()
    } else {
      next()
    }
  } else {
    if (to.path == '/' || (to.path != '/' && whiteList.indexOf(to.path) !== -1)) {
      next()
    } else {
      NProgress.done()
      MessageBox.confirm('Sorry, you need to login first', 'system prompt', {
        confirmButtonText: 'Login',
        callback: (action, instance) => {
          if (action === 'confirm') {
            next({path: '/login'})
          } else {
            console.log(from);
            next(from)
          }
        }
      })
    }
  }
})

router.afterEach(() => {
  NProgress.done()
})
