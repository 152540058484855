import Vue from 'vue'
import { ACCESS_TOKEN,HEAD_IMG_URL,USER_UUID } from "@/store/mutation-types"
const getters = {
  token: state => {
    state.user.token = Vue.ls.get(ACCESS_TOKEN)
    return state.user.token
  },
  headimgurl: state => {
    state.user.headimgurl = Vue.ls.get(HEAD_IMG_URL)
    return state.user.headimgurl
  },
  useruuid: state => {
    state.user.useruuid = Vue.ls.get(USER_UUID)
    return state.user.useruuid
  }
}
export default getters
