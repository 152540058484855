import axios from "@/utils/requestApi"

//post
export function postActionApi(url, parameter) {
  return axios({
    url: url,
    method:'post' ,
    data: parameter
  })
}

//put
export function putActionApi(url, parameter) {
  return axios({
    url: url,
    method:'put',
    data: parameter
  })
}

//get
export function getActionApi(url, parameter) {
  return axios({
    url: url,
    method: 'get',
    params: parameter
  })
}

//delete
export function deleteActionApi(url, parameter) {
  return axios({
    url: url,
    method: 'delete',
    data: parameter
  })
}

/**
 * 下载文件 用于excel导出
 * @param url
 * @param parameter
 * @returns {*}
 */
export function downFileApi(url, parameter){
  return axios({
    url: url,
    params: parameter,
    method:'get' ,
    responseType: 'blob'
  })
}
